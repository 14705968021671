import { CertificateBundleDto } from '../../../api/types'
import { useState } from 'react'
import { Box, IconButton, List, ListItem, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CustomDialog from '../../../features/custom-dialog/CustomDialog'
import CancelButton from '../../../features/buttons/CancelButton'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import {
  bundleHasInvestmentSupport,
  bundleHasInvestmentSupportDescription,
  bundleHasProductionSupport,
  bundleHasProductionSupportDescription
} from "./filters";

interface IBundleInfoProps {
  bundle: CertificateBundleDto
}

export const BundleInfo = ({ bundle }: IBundleInfoProps) => {
  const { t } = useTranslation()
  const [dialogOpen, setDialogOpen] = useState(false)
  const labelColumnStyle = { flex: '1 1 0' }
  const dataColumnStyle = { flex: '2 1 0' }
  const borderTopStyle = { borderTop: '1px solid #D5DDE3' }
  const borderBottomStyle = { borderBottom: '1px solid #D5DDE3' }
  const rowPaddingStyle = { paddingTop: '8px', paddingBottom: '8px' }

  const LabelCell = ({ children }) => {
    return <Box sx={labelColumnStyle}>{children}</Box>
  }

  const DataCell = ({ children }) => {
    return <Box sx={dataColumnStyle}>{children}</Box>
  }

  return (
    <>
      <IconButton aria-label="bundle-info" onClick={() => setDialogOpen(true)}>
        <InfoOutlinedIcon />
      </IconButton>
      <CustomDialog
        isOpen={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        buttons={
          <>
            <CancelButton handleCancel={() => setDialogOpen(false)} />
          </>
        }
      >
        <Box>
          <FlexColumn gap="40px">
            <FlexColumn>
              <Typography variant="h3" sx={{ paddingBottom: 2 }}>
                {t('bundle.bundle')}
              </Typography>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.amount')}</Typography>
                </LabelCell>
                <DataCell>
                  <Typography>{bundle.amount}</Typography>
                </DataCell>
              </FlexRow>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.range')}</Typography>
                </LabelCell>
                <DataCell>
                  <FlexColumn>
                    <Box>
                      <Typography>{bundle.numberRangeStart}</Typography>
                    </Box>
                    <Box>
                      <Typography>{bundle.numberRangeEnd}</Typography>
                    </Box>
                  </FlexColumn>
                </DataCell>
              </FlexRow>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.productionMonth')}</Typography>
                </LabelCell>
                <DataCell>
                  <Typography>{dayjs(bundle.certificateProductionPeriodEnd).format('MM/YYYY')}</Typography>
                </DataCell>
              </FlexRow>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.productionTime')}</Typography>
                </LabelCell>
                <DataCell>
                  <Typography>
                    {dayjs(bundle.certificateProductionPeriodStart).format('DD.MM.YYYY')} -{' '}
                    {dayjs(bundle.certificateProductionPeriodEnd).format('DD.MM.YYYY')}
                  </Typography>
                </DataCell>
              </FlexRow>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.fuelType')}</Typography>
                </LabelCell>
                <DataCell>
                  <Typography>
                    {bundle.certificateFuelTypeName} {`(${bundle.certificateFuelTypeCode})`}
                  </Typography>
                </DataCell>
              </FlexRow>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.natures')}</Typography>
                </LabelCell>
                <DataCell>
                  <Typography>{bundle.certificateNatures?.join(',')}</Typography>
                </DataCell>
              </FlexRow>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.labels')}</Typography>
                </LabelCell>
                <DataCell>
                  <Typography>{bundle.certificateLabelCodes?.join(',')}</Typography>
                </DataCell>
              </FlexRow>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.investmentSupport')}</Typography>
                </LabelCell>
                <DataCell>
                  <FlexColumn>
                    <Box>
                      <Typography>
                        {bundleHasInvestmentSupport(bundle)
                          ? t('bundle.hasSupport')
                          : t('bundle.hasNoSupport')}
                      </Typography>
                    </Box>
                    <List sx={{ listStyleType: 'disc', pl: 4, display: bundleHasInvestmentSupportDescription(bundle) ? 'block' : 'none' }}>
                      {bundle.certificateInvestmentSupportDescriptions?.map((desc) => (
                        <ListItem key={desc} sx={{ display: 'list-item' }}>
                          {desc}
                        </ListItem>
                      ))}
                    </List>
                  </FlexColumn>
                </DataCell>
              </FlexRow>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle, ...borderBottomStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.productionSupport')}</Typography>
                </LabelCell>
                <DataCell>
                  <FlexColumn>
                    <Box>
                      <Typography>
                        {bundleHasProductionSupport(bundle)
                          ? t('bundle.hasSupport')
                          : t('bundle.hasNoSupport')}
                      </Typography>
                    </Box>
                    <List sx={{ listStyleType: 'disc', pl: 4, display: bundleHasProductionSupportDescription(bundle) ? 'block' : 'none' }}>
                      {bundle.certificateProductionSupportDescriptions?.map((desc) => (
                        <ListItem key={desc} sx={{ display: 'list-item' }}>
                          {desc}
                        </ListItem>
                      ))}
                    </List>
                  </FlexColumn>
                </DataCell>
              </FlexRow>
            </FlexColumn>

            <FlexColumn>
              <Typography variant="h3" sx={{ paddingBottom: 2 }}>
                {t('bundle.issuing')}
              </Typography>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.issuingDate')}</Typography>
                </LabelCell>
                <DataCell>
                  <Typography>{dayjs(bundle.certificateIssuingDate).format('DD.MM.YYYY')}</Typography>
                </DataCell>
              </FlexRow>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.issuingCountry')}</Typography>
                </LabelCell>
                <DataCell>
                  <Typography>{bundle.certificateIssuingCountry}</Typography>
                </DataCell>
              </FlexRow>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.issuingBody')}</Typography>
                </LabelCell>
                <DataCell>
                  <Typography>
                    {bundle.certificateIssuingBodyName} ({bundle.certificateIssuingBodyCode})
                  </Typography>
                </DataCell>
              </FlexRow>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle, ...borderBottomStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.competentAuthority')}</Typography>
                </LabelCell>
                <DataCell>
                  <Typography>{bundle.certificateCompetentAuthorities?.join(', ')}</Typography>
                </DataCell>
              </FlexRow>
            </FlexColumn>

            <FlexColumn>
              <Typography variant="h3" sx={{ paddingBottom: 2 }}>
                {t('bundle.productionDevice')}
              </Typography>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.productionDeviceName')}</Typography>
                </LabelCell>
                <DataCell>
                  <Typography>{bundle.productionDeviceName}</Typography>
                </DataCell>
              </FlexRow>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.productionDeviceGsrn')}</Typography>
                </LabelCell>
                <DataCell>
                  <Typography>{bundle.productionDeviceGsrn}</Typography>
                </DataCell>
              </FlexRow>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.productionDeviceDateOfCommissioning')}</Typography>
                </LabelCell>
                <DataCell>
                  <Typography>{dayjs(bundle.productionDeviceDateOfCommissioning).format('DD.MM.YYYY')}</Typography>
                </DataCell>
              </FlexRow>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.productionDeviceAddress')}</Typography>
                </LabelCell>
                <DataCell>
                  <Typography>
                    {bundle.productionDevicePostNumber} {bundle.productionDeviceRegion}
                  </Typography>
                </DataCell>
              </FlexRow>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.productionDeviceCoordinates')}</Typography>
                </LabelCell>
                <DataCell>
                  <Typography>
                    {bundle.productionDeviceCoordinates
                      ? `${bundle.productionDeviceCoordinateSystem}: ${bundle.productionDeviceLatitude}, ${bundle.productionDeviceLongitude}`
                      : ''}
                  </Typography>
                </DataCell>
              </FlexRow>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.productionDeviceCapacity')}</Typography>
                </LabelCell>
                <DataCell>
                  <Typography>{bundle.productionDeviceCapacity}</Typography>
                </DataCell>
              </FlexRow>
              <FlexRow styles={{ ...borderTopStyle, ...rowPaddingStyle, ...borderBottomStyle }}>
                <LabelCell>
                  <Typography>{t('bundle.productionDeviceTechnology')}</Typography>
                </LabelCell>
                <DataCell>
                  <Typography>
                    {bundle.productionDeviceTechnologyName} ({bundle.productionDeviceTechnologyCode})
                  </Typography>
                </DataCell>
              </FlexRow>
            </FlexColumn>
          </FlexColumn>
        </Box>
      </CustomDialog>
    </>
  )
}
