import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { TextField } from '@mui/material'
import { CalendarPickerView, DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useAppLanguage } from '../../hooks'
import { getValue } from '../../utils/utils'
import StyleGuide from '../../styles/StyleGuide'
import InputContainer from './InputContainer'
import { Dayjs } from 'dayjs'

interface ControlledDatePickerProps<T extends FieldValues, P extends Path<T>> {
  id?: string
  name: P
  label?: string
  required?: boolean
  fullWidth?: boolean
  views?: CalendarPickerView[]
  disabled?: boolean
  control: Control<T>
  maxDate?: Dayjs
  minDate?: Dayjs
}

const ControlledDatePicker = <T extends FieldValues, P extends Path<T>>({
  id,
  name,
  label,
  required,
  fullWidth = true,
  views = ['year', 'day'],
  disabled = false,
  control,
  maxDate,
  minDate
}: ControlledDatePickerProps<T, P>) => {
  const { currentLanguage } = useAppLanguage()

  return (
    <InputContainer id={id} label={label} required={required}>
      <Controller
        control={control}
        name={name}
        render={({ field, formState }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={currentLanguage}>
              <DatePicker
                maxDate={maxDate}
                minDate={minDate}
                disabled={disabled}
                views={views}
                value={field.value}
                onChange={(newValue) => {
                  // If maxDate is set we need to force the year to be within the range
                  if (newValue && maxDate) {
                    if (newValue.year() > maxDate.year()) {
                      newValue = newValue.set('year', maxDate.year())
                    }
                  }

                  // If minDate is set we need to force the year to be within the range
                  if (newValue && minDate) {
                    if (newValue.year() < minDate.year()) {
                      newValue = newValue.set('year', minDate.year())
                    }
                  }

                  field.onChange(newValue?.format('YYYY-MM-DD'))

                  if (!newValue) field.onChange(undefined)
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      onBlur={field.onBlur}
                      fullWidth={fullWidth}
                      sx={{
                        height: 'fit-content',
                        '.MuiFormHelperText-root': {
                          color: StyleGuide.constants.COLOR_BRAND
                        }
                      }}
                      {...params}
                      error={!!getValue(formState.errors, name)}
                      helperText={getValue(formState.errors, name) ? getValue(formState.errors, `${name}.message`) : ''}
                    />
                  )
                }}
              />
            </LocalizationProvider>
          )
        }}
      />
    </InputContainer>
  )
}

export default ControlledDatePicker
