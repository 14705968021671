import { CertificateBundleDto, CertificateBundleDtoCertificateSupportFlag } from '../../../api/types'
import dayjs from 'dayjs'

export const filteredBundles = (filters, bundles: CertificateBundleDto[], t): CertificateBundleDto[] => {
  return bundles.filter((bundle) => {
    if (filters.productionTimeDateRange) {
      if (
        filters.productionTimeDateRange.start &&
        dayjs(bundle.certificateProductionPeriodStart).isBefore(dayjs(filters.productionTimeDateRange.start))
      ) {
        return false
      }

      if (
        filters.productionTimeDateRange.end &&
        dayjs(bundle.certificateProductionPeriodStart).isAfter(dayjs(filters.productionTimeDateRange.end))
      ) {
        return false
      }
    }

    if (
      filters.productionDeviceTechnologies.length > 0 &&
      !filters.productionDeviceTechnologies.includes(bundle.productionDeviceTechnologyName!)
    ) {
      return false
    }

    if (
      filters.productionDeviceNames.length > 0 &&
      !filters.productionDeviceNames.includes(bundle.productionDeviceName!)
    ) {
      return false
    }

    if (filters.productionDeviceCommissioningDateRange) {
      if (
        filters.productionDeviceCommissioningDateRange.start &&
        dayjs(bundle.productionDeviceDateOfCommissioning).isBefore(
          dayjs(filters.productionDeviceCommissioningDateRange.start)
        )
      ) {
        return false
      }

      if (
        filters.productionDeviceCommissioningDateRange.end &&
        dayjs(bundle.productionDeviceDateOfCommissioning).isAfter(
          dayjs(filters.productionDeviceCommissioningDateRange.end)
        )
      ) {
        return false
      }
    }

    // If no selection, do nothing. If both selected, again do nothing.
    if (filters.productionDeviceInvestments.no && !filters.productionDeviceInvestments.yes) {
      if (bundleHasSupport(bundle)) {
        return false
      }
    } else if (!filters.productionDeviceInvestments.no && filters.productionDeviceInvestments.yes) {
      if (!bundleHasSupport(bundle)) {
        return false
      }
    }

    if (
      filters.productionDeviceCapacity.min &&
      +bundle.productionDeviceCapacity! < +filters.productionDeviceCapacity.min.replace(',', '.')
    ) {
      return false
    }

    if (
      filters.productionDeviceCapacity.max &&
      +bundle.productionDeviceCapacity! > +filters.productionDeviceCapacity.max.replace(',', '.')
    ) {
      return false
    }

    if (filters.labelCodes.length > 0) {
      if (filters.labelCodes.length === 1 && filters.labelCodes.includes(t('account.filter.noLabel'))) {
        if (bundle.certificateLabelCodes && bundle.certificateLabelCodes.length > 0) return false
      }

      if ((bundle.certificateLabelCodes?.length || 0) > 0) {
        bundle.certificateLabelCodes?.forEach((icsCode) => {
          if (filters.labelCodes.includes(icsCode)) {
            return false
          }
        })
      } else if (!filters.labelCodes.includes(t('account.filter.noLabel'))) {
        return false
      }
    }

    if (
      filters.certificateIssuingCountries.length > 0 &&
      !filters.certificateIssuingCountries.includes(bundle.certificateIssuingCountry!)
    ) {
      return false
    }

    return true
  })
}

export const bundleHasSupport = (obj: CertificateBundleDto) => {
  return (
    obj.certificateSupportFlag === CertificateBundleDtoCertificateSupportFlag.INVESTMENT_SUPPORT ||
    obj.certificateSupportFlag === CertificateBundleDtoCertificateSupportFlag.PRODUCTION_SUPPORT ||
    obj.certificateSupportFlag === CertificateBundleDtoCertificateSupportFlag.INVESTMENT_AND_PRODUCTION_SUPPORT
  )
}

export const bundleHasInvestmentSupport = (obj: CertificateBundleDto) => {
  return (
    obj.certificateSupportFlag === CertificateBundleDtoCertificateSupportFlag.INVESTMENT_SUPPORT ||
    obj.certificateSupportFlag === CertificateBundleDtoCertificateSupportFlag.INVESTMENT_AND_PRODUCTION_SUPPORT
  )
}

export const bundleHasProductionSupport = (obj: CertificateBundleDto)=> {
  return (
    obj.certificateSupportFlag === CertificateBundleDtoCertificateSupportFlag.PRODUCTION_SUPPORT ||
    obj.certificateSupportFlag === CertificateBundleDtoCertificateSupportFlag.INVESTMENT_AND_PRODUCTION_SUPPORT
  )
}

export const bundleHasInvestmentSupportDescription = (obj: CertificateBundleDto) => {
  return obj.certificateInvestmentSupportDescriptions && obj.certificateInvestmentSupportDescriptions?.length > 0
}

export const bundleHasProductionSupportDescription = (obj: CertificateBundleDto) => {
  return obj.certificateProductionSupportDescriptions && obj.certificateProductionSupportDescriptions?.length > 0
}