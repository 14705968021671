import { Box, Divider, Skeleton, Tab, Tabs } from '@mui/material'
import { FlexColumn, FlexRow } from '../../styles/containers/FlexContainers'
import { useTranslation } from 'react-i18next'

export default function OrganizationAccountSkeleton() {
  const { t } = useTranslation()

  return (
    <FlexColumn gap="10px">
      <FlexRow justifyContent="space-between" alignItems="center">
        <Skeleton variant="text" width={690} height={40} />
      </FlexRow>

      <FlexRow justifyContent="space-between" alignItems="flex-end">
        <FlexRow>
          <Skeleton variant="text" width={117} height={24} />
          <Divider orientation="vertical" sx={{ marginLeft: '10px', marginRight: '10px', height: '20px' }} />
          <Skeleton variant="text" width={187} height={24} />
          <Divider orientation="vertical" sx={{ marginLeft: '10px', marginRight: '10px', height: '20px' }} />
          <Skeleton variant="text" width={168} height={24} />
          <Divider orientation="vertical" sx={{ marginLeft: '10px', marginRight: '10px', height: '20px' }} />
          <Skeleton variant="text" width={80} height={24} />
        </FlexRow>
      </FlexRow>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={1} aria-label="tabs">
          <Tab label={t('account.actions')} />
          <Tab label={t('account.transactions')} />
          <Tab label={t('account.automaticTransfer.title')} />
        </Tabs>
      </Box>

      <Skeleton variant="rectangular" width="100%" height={400} />
    </FlexColumn>
  )
}
